/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { LiveVideoRoomParticipantTrack, StaffType, LiveVideoRoomParticipantTracks } from '@irontrainers/live-assist/lib/rtdb'
import { size } from 'lodash'

import ironFit, { liveSupport, patients } from '../../../clients/iron-fit'
import { Timer, PatientName, StaffName, PulsatingDot, useTimeIntervalTicker } from '../../../components'

declare var screen: any

export interface LiveUserKanbanCardProps {
  userId: string
  userType: StaffType | 'patient'
  awaitingSince?: number
  quitSince?: number
  queuePriority?: number
  returningSupportId?: string
  onlineDevices?: number
  supportId?: string
  supportStartTime?: number
  supportEndTime?: number
  preferredStaffId?: string
  indexNum?: any
  patientName?: any
}

function getPopupSettings() {
  const width = screen.availWidth
  const height = screen.availHeight
  return `scrollbars=yes,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=${width},height=${height}`
}

export const LiveUserKanbanCard: React.FC<LiveUserKanbanCardProps> = ({
  userId,
  userType,
  awaitingSince,
  quitSince,
  onlineDevices,
  queuePriority,
  returningSupportId,
  supportId,
  supportStartTime,
  supportEndTime,
  indexNum,
  patientName
}) => {
  useTimeIntervalTicker(30 * 1000)

  const api = ironFit._clients().api

  const [support, supportLoading] = liveSupport.useLiveSupport(userType !== 'patient' ? supportId : undefined)
  const supportingPatientId = support && support.patientId && !support.patientReviewTime ? support.patientId : undefined
  const [supportingPatient, supportingPatientLoading] = liveSupport.useLivePatient(supportingPatientId)
  // const [returningSupport, returningSupportLoading] = liveSupport.useLiveSupport(userType !== 'patient' ? returningSupportId : undefined)
  const [videoRoom, videoRoomLoading] = liveSupport.useVideoRoom(supportId)
  const videoRoomCurrentParticipant = videoRoom && videoRoom.participants ? videoRoom.participants[userId] : undefined
  const videoRoomCurrentParticipantTracks = videoRoomCurrentParticipant ? videoRoomCurrentParticipant.tracks : undefined
  const videoRoomSupportingParticipant =
    videoRoom && videoRoom.participants && supportingPatientId ? videoRoom.participants[supportingPatientId] : undefined
  const videoRoomSupportingParticipantTracks = videoRoomSupportingParticipant ? videoRoomSupportingParticipant.tracks : undefined
  const isVideoRoomInProgress = videoRoom && videoRoom.status === 'in-progress'

  const [patientData] = patients.usePatient(userId)
  // console.log(patient)

  const loading =
    supportLoading ||
    // || returningSupportLoading
    supportingPatientLoading ||
    videoRoomLoading

  const mediaStatus = useVideoRoomMediaState(videoRoomCurrentParticipantTracks)
  const supportingPatientMediaStatus = useVideoRoomMediaState(videoRoomSupportingParticipantTracks)

  const supportingPatientOnlineDevices = supportingPatient ? size(supportingPatient.onlineDevices) : 0
  const onlineClassName = onlineDevices && onlineDevices >= 1 ? '' : 'text-danger'
  const supportingPatientOnlineClassName = supportingPatientOnlineDevices >= 1 ? '' : 'text-danger'

  const handleByPass = async () => {
    console.log(api)
    try {
      const res = await api.post('/live-support/bypass', {
        transferToDoctor: true,
        transferWithPriority: 1,
        patientId: userId,
      })
      return res.data
    } catch (e) {
      throw e
    }
  }

  const openSupportDebugPopup = React.useCallback(() => {
    if (!supportId) return false
    const url = new URL(window.location.href)
    url.searchParams.set('supportId', supportId)
    url.pathname = '/live-debug'
    window.open(url.href, `live-debug-${Date.now()}`, getPopupSettings())
    return false
  }, [supportId])

  const openUserDebugPopup = React.useCallback(() => {
    if (!userId) return false
    if (!userType) return false
    const url = new URL(window.location.href)
    url.searchParams.set(userType === 'patient' ? 'patientId' : 'staffId', userId)
    url.pathname = '/live-debug'
    window.open(url.href, `live-debug-${Date.now()}`, getPopupSettings())
    return false
  }, [userId, userType])

  const openSupportingPatientDebugPopup = React.useCallback(() => {
    if (!supportingPatientId) return false
    const url = new URL(window.location.href)
    url.searchParams.set('patientId', supportingPatientId)
    url.pathname = '/live-debug'
    window.open(url.href, `live-debug-${Date.now()}`, getPopupSettings())
    return false
  }, [supportingPatientId])

  const fiveMinutesAgo = Date.now() - 5 * 60 * 1000
  if (quitSince && quitSince < fiveMinutesAgo) return null

  const fallbackSupportStartTime = supportStartTime || (support && support.startTime)
  const fallbackSupportEndTime = supportEndTime || (support && support.endTime)


  // console.log("patientName ==", patientName)
  // console.log("patientData ==", patientData?.name)

  if( patientName !== undefined && patientData?.name!== undefined &&  patientData?.name.toUpperCase().includes(patientName.toUpperCase())) {

    return (
      <div className="card card-progress border shadow-none" onClick={openSupportDebugPopup}>
        <div className="card-body">
          <div className="row align-items-center mb-2">
            <div className="col-6">
              {quitSince && <span className="badge badge-pill badge-xs badge-warning text-uppercase mr-2">Video Off</span>}
              {awaitingSince && <span className="badge badge-pill badge-xs badge-danger text-uppercase mr-2">Waiting</span>}
              {supportId && !fallbackSupportEndTime && (
                <span className="badge badge-pill badge-xs badge-success text-uppercase mr-2">In Support</span>
              )}
              {supportId && fallbackSupportEndTime && (
                <span className="badge badge-pill badge-xs badge-warning text-uppercase mr-2">In Review</span>
              )}
              {/* {isReturn && (<span className="badge badge-pill badge-xs badge-danger">Return</span>)} */}
              {/* {queuePriority !== undefined && (<span className="badge badge-pill badge-xs badge-warning">Priority {queuePriority}</span>)} */}
            </div>
            <div className="col-6 d-flex justify-content-end">
              {loading && (
                <div className="spinner-border text-primary mr-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {isVideoRoomInProgress && <PulsatingDot className="mr-2" />}
              {quitSince && <Timer startTime={quitSince} />}
              {awaitingSince && <Timer startTime={awaitingSince} />}
              {supportId && fallbackSupportStartTime && !fallbackSupportEndTime && <Timer startTime={fallbackSupportStartTime} />}
              {supportId && fallbackSupportStartTime && fallbackSupportEndTime && <Timer startTime={fallbackSupportEndTime} />}
              {/* {onlineDevices && (<i className="fas fa-circle mr-2 text-success" title={`${onlineDevices} ${onlineDevices === 1 ? 'device' : 'devices'}`}></i>)} */}
              {/* {!onlineDevices && (<i className="fas fa-circle mr-2 text-warning" title="Offline"></i>)} */}
            </div>
          </div>
          <a className="h6 text-uppercase text-truncate d-inline-block" style={{ maxWidth: 245 }} target="popup" onClick={openUserDebugPopup}>
            {userType === 'patient' && <i className={`fas fa-user mr-2 ${onlineClassName}`}></i>}
            {userType === 'operator' && <i className={`fas fa-user-nurse mr-2 ${onlineClassName}`}></i>}
            {userType === 'doctor' && <i className={`fas fa-user-md mr-2 ${onlineClassName}`}></i>}
            {userType === 'patient' && <PatientName patientId={userId} />}
            {userType !== 'patient' && <StaffName staffId={userId} />}

          </a>
          {indexNum ? <div>Index:{indexNum}</div> : null}
          <div className="d-flex mb-3">
            <div className="mr-2">
              <i className="far fa-window-restore mr-2"></i>
              {onlineDevices || 0}
            </div>
            {mediaStatus.video && (
              <div className="mr-2">
                {mediaStatus.video === 'offline' && <i className="fas fa-video"></i>}
                {mediaStatus.video === 'enabled' && <i className="fas fa-video text-success"></i>}
                {mediaStatus.video === 'disabled' && <i className="fas fa-video-slash text-warning"></i>}
              </div>
            )}
            {mediaStatus.microphone && (
              <div className="mr-2">
                {mediaStatus.microphone === 'offline' && <i className="fas fa-microphone"></i>}
                {mediaStatus.microphone === 'enabled' && <i className="fas fa-microphone text-success"></i>}
                {mediaStatus.microphone === 'disabled' && <i className="fas fa-microphone-slash text-warning"></i>}
              </div>
            )}
            {queuePriority !== undefined && (
              <div className="mr-2">
                <i className="fas fa-exclamation text-warning mr-2"></i>
                <span className="text-warning">{queuePriority}</span>
              </div>
            )}
            {!!returningSupportId && (
              <div className="mr-2">
                <i className="fas fa-redo-alt text-warning"></i>
              </div>
            )}
            {userType === 'patient' && queuePriority !== 1 && (
              <i style={{ cursor: 'pointer' }} className={`far fa fa-forward mt-1 ml-2`} onClick={() => handleByPass()}></i>
            )}
          </div>

          {supportingPatient && supportingPatientId && (
            <>
              <a
                className="h6 text-uppercase text-truncate d-inline-block"
                style={{ maxWidth: 245 }}
                target="popup"
                onClick={openSupportingPatientDebugPopup}
              >
                <i className={`fas fa-user mr-2 ${supportingPatientOnlineClassName}`}></i>
                <PatientName patientId={supportingPatientId} />

              </a>
              <div className="d-flex mb-3">
                <div className="mr-2">
                  <i className="far fa-window-restore mr-2"></i>
                  {supportingPatientOnlineDevices}
                </div>
                {supportingPatientMediaStatus.video && (
                  <div className="mr-2">
                    {supportingPatientMediaStatus.video === 'offline' && <i className="fas fa-video"></i>}
                    {supportingPatientMediaStatus.video === 'enabled' && <i className="fas fa-video text-success"></i>}
                    {supportingPatientMediaStatus.video === 'disabled' && <i className="fas fa-video-slash text-warning"></i>}
                  </div>
                )}
                {supportingPatientMediaStatus.microphone && (
                  <div className="mr-2">
                    {supportingPatientMediaStatus.microphone === 'offline' && <i className="fas fa-microphone"></i>}
                    {supportingPatientMediaStatus.microphone === 'enabled' && <i className="fas fa-microphone text-success"></i>}
                    {supportingPatientMediaStatus.microphone === 'disabled' && <i className="fas fa-microphone-slash text-warning"></i>}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )

  } else if(patientName !== "") {

    return (
      <div></div>
    )

  } else {
    return (
      <div className="card card-progress border shadow-none" onClick={openSupportDebugPopup}>
        <div className="card-body">
          <div className="row align-items-center mb-2">
            <div className="col-6">
              {quitSince && <span className="badge badge-pill badge-xs badge-warning text-uppercase mr-2">Video Off</span>}
              {awaitingSince && <span className="badge badge-pill badge-xs badge-danger text-uppercase mr-2">Waiting</span>}
              {supportId && !fallbackSupportEndTime && (
                <span className="badge badge-pill badge-xs badge-success text-uppercase mr-2">In Support</span>
              )}
              {supportId && fallbackSupportEndTime && (
                <span className="badge badge-pill badge-xs badge-warning text-uppercase mr-2">In Review</span>
              )}
              {/* {isReturn && (<span className="badge badge-pill badge-xs badge-danger">Return</span>)} */}
              {/* {queuePriority !== undefined && (<span className="badge badge-pill badge-xs badge-warning">Priority {queuePriority}</span>)} */}
            </div>
            <div className="col-6 d-flex justify-content-end">
              {loading && (
                <div className="spinner-border text-primary mr-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {isVideoRoomInProgress && <PulsatingDot className="mr-2" />}
              {quitSince && <Timer startTime={quitSince} />}
              {awaitingSince && <Timer startTime={awaitingSince} />}
              {supportId && fallbackSupportStartTime && !fallbackSupportEndTime && <Timer startTime={fallbackSupportStartTime} />}
              {supportId && fallbackSupportStartTime && fallbackSupportEndTime && <Timer startTime={fallbackSupportEndTime} />}
              {/* {onlineDevices && (<i className="fas fa-circle mr-2 text-success" title={`${onlineDevices} ${onlineDevices === 1 ? 'device' : 'devices'}`}></i>)} */}
              {/* {!onlineDevices && (<i className="fas fa-circle mr-2 text-warning" title="Offline"></i>)} */}
            </div>
          </div>
          <a className="h6 text-uppercase text-truncate d-inline-block" style={{ maxWidth: 245 }} target="popup" onClick={openUserDebugPopup}>
            {userType === 'patient' && <i className={`fas fa-user mr-2 ${onlineClassName}`}></i>}
            {userType === 'operator' && <i className={`fas fa-user-nurse mr-2 ${onlineClassName}`}></i>}
            {userType === 'doctor' && <i className={`fas fa-user-md mr-2 ${onlineClassName}`}></i>}
            {userType === 'patient' && <PatientName patientId={userId} />}
            {userType !== 'patient' && <StaffName staffId={userId} />}

          </a>
          {indexNum ? <div>Index:{indexNum}</div> : null}
          <div className="d-flex mb-3">
            <div className="mr-2">
              <i className="far fa-window-restore mr-2"></i>
              {onlineDevices || 0}
            </div>
            {mediaStatus.video && (
              <div className="mr-2">
                {mediaStatus.video === 'offline' && <i className="fas fa-video"></i>}
                {mediaStatus.video === 'enabled' && <i className="fas fa-video text-success"></i>}
                {mediaStatus.video === 'disabled' && <i className="fas fa-video-slash text-warning"></i>}
              </div>
            )}
            {mediaStatus.microphone && (
              <div className="mr-2">
                {mediaStatus.microphone === 'offline' && <i className="fas fa-microphone"></i>}
                {mediaStatus.microphone === 'enabled' && <i className="fas fa-microphone text-success"></i>}
                {mediaStatus.microphone === 'disabled' && <i className="fas fa-microphone-slash text-warning"></i>}
              </div>
            )}
            {queuePriority !== undefined && (
              <div className="mr-2">
                <i className="fas fa-exclamation text-warning mr-2"></i>
                <span className="text-warning">{queuePriority}</span>
              </div>
            )}
            {!!returningSupportId && (
              <div className="mr-2">
                <i className="fas fa-redo-alt text-warning"></i>
              </div>
            )}
            {userType === 'patient' && queuePriority !== 1 && (
              <i style={{ cursor: 'pointer' }} className={`far fa fa-forward mt-1 ml-2`} onClick={() => handleByPass()}></i>
            )}
          </div>

          {supportingPatient && supportingPatientId && (
            <>
              <a
                className="h6 text-uppercase text-truncate d-inline-block"
                style={{ maxWidth: 245 }}
                target="popup"
                onClick={openSupportingPatientDebugPopup}
              >
                <i className={`fas fa-user mr-2 ${supportingPatientOnlineClassName}`}></i>
                <PatientName patientId={supportingPatientId} />

              </a>
              <div className="d-flex mb-3">
                <div className="mr-2">
                  <i className="far fa-window-restore mr-2"></i>
                  {supportingPatientOnlineDevices}
                </div>
                {supportingPatientMediaStatus.video && (
                  <div className="mr-2">
                    {supportingPatientMediaStatus.video === 'offline' && <i className="fas fa-video"></i>}
                    {supportingPatientMediaStatus.video === 'enabled' && <i className="fas fa-video text-success"></i>}
                    {supportingPatientMediaStatus.video === 'disabled' && <i className="fas fa-video-slash text-warning"></i>}
                  </div>
                )}
                {supportingPatientMediaStatus.microphone && (
                  <div className="mr-2">
                    {supportingPatientMediaStatus.microphone === 'offline' && <i className="fas fa-microphone"></i>}
                    {supportingPatientMediaStatus.microphone === 'enabled' && <i className="fas fa-microphone text-success"></i>}
                    {supportingPatientMediaStatus.microphone === 'disabled' && <i className="fas fa-microphone-slash text-warning"></i>}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }


}

function useVideoRoomMediaState(tracks?: LiveVideoRoomParticipantTracks) {
  return React.useMemo(() => {
    if (!tracks) return {}

    let audioTrack: LiveVideoRoomParticipantTrack | undefined = undefined
    let videoTrack: LiveVideoRoomParticipantTrack | undefined = undefined
    for (const trackId in tracks) {
      const track = tracks[trackId]
      if (track.kind === 'video') videoTrack = track
      if (track.kind === 'audio') audioTrack = track
    }

    type TrackStatus = 'offline' | 'enabled' | 'disabled'
    const microphone: TrackStatus = audioTrack ? (audioTrack.enabled ? 'enabled' : 'disabled') : 'offline'
    const video: TrackStatus = videoTrack ? (videoTrack.enabled ? 'enabled' : 'disabled') : 'offline'

    return { microphone, video }
  }, [tracks])
}
